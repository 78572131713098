import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/mdx-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "monochrome-mostly-game-suggestions"
    }}>{`monochrome (mostly) game suggestions`}</h1>
    <p>{`1-bit (or 1-bit plus one colour) have a nice aesthetic`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      